import React, { useEffect } from "react";
// import "./reset-password.css";
import VideoPlayer from "../common/VideoPlayer";
import { FormControl, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { textFieldStyles } from "../../styles/inputStyles";
import {
  sendOTPtoEmail,
  sendPasswordValidationOTPtoEmail,
} from "../../api/auth";

const TeamMembersResetPassword = () => {
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const [errors, setErrors] = useState({ email: "", root: "" });
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setErrors({ email: "Enter registered Email Address!" });
    } else {
      try {
        let response = await sendPasswordValidationOTPtoEmail({
          user_email: email,
        });
        if (response.data.status === 200) {
          // await api call and onsuccess redirect
          localStorage.setItem("resetEmail", email);
          navigate("/team-reset-password/otp");
        } else {
          throw new Error("Enter the registered Email Address!");
        }
      } catch (error) {
        setErrors({ ...errors, root: "Enter the registered Email Address!" });
      }
    }
  };

  useEffect(() => {
    if (email === "") {
      setErrors({ email: "" });
    }
    setIsValid(validateEmail(email));
  }, [email]);

  return (
    <div className="reset-password_container">
      <div className="video-player">
        <VideoPlayer />
      </div>

      <div className="reset-form_container">
        <div className="reset-form_div">
          <Typography
            variant="h1"
            gutterBottom
            style={{
              fontFamily: "Inter",
              fontSize: "29px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Reset your password
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: "16px",
              marginBottom: "2rem",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: 15,
              color: "#8692A6",
              textAlign: "center",
            }}
          >
            We will send you OTP on your registered Email Address
          </Typography>

          <form onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ mb: errors.root ? 1 : 5 }} required>
              <TextField
                label="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                // sx={{
                //   fontSize: "14px",
                //   input: {
                //     fontSize: "14px",
                //   },
                //   "& .MuiFormLabel-root": {
                //     fontSize: "14px",
                //   },
                //   "& .MuiInputBase-root": {
                //     fontSize: "14px",
                //     borderRadius: "5px",
                //   },
                //   "& .Mui-focused .MuiFormLabel-root": {
                //     color: "gray", // Label color when focused (floating)
                //   },
                // }}
                sx={textFieldStyles}
              />
            </FormControl>

            {errors.root && (
              <p
                style={{
                  fontSize: "14px",
                  margin: "0 0 1rem 0",
                  color: "#F51934",
                }}
              >
                {errors.root}
              </p>
            )}

            <button
              type="submit"
              disabled={!isValid}
              style={{
                width: "100%",
                padding: "14px 0",
                borderRadius: 6,
                background: !isValid ? "#a0a0a0" : "",
                backgroundImage: isValid
                  ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                  : "",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "Inter",
                color: "#fff",
                border: "1px solid #DDD",
                letterSpacing: "0.5px",
                cursor: "pointer",
              }}
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TeamMembersResetPassword;
