import React, { useState, useEffect } from "react";
// import "./reset-password.css";
import VideoPlayer from "../common/VideoPlayer";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordChangedModal from "../Authentication/PasswordChangedModal";
import { textFieldStyles } from "../../styles/inputStyles";
import { resetPassword } from "../../api/auth";

const TeamMembersNewPassword = () => {
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
    root: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const navigate = useNavigate();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const isPasswordMatching = passwords.password === passwords.confirmPassword;
  const isPasswordFormatValid = passwordRegex.test(passwords.password);

  const isFormValid =
    isPasswordFormatValid && isPasswordMatching && passwords.password !== "";

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });

    if (name === "password") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: passwordRegex.test(value)
          ? ""
          : "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.",
      }));
    } else if (name === "confirmPassword") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          value === passwords.password && value !== ""
            ? ""
            : "Passwords do not match.",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      user_password: passwords.password,
      user_confirm_password: passwords.confirmPassword,
    };

    try {
      const res = await resetPassword(data);
      if (res?.status === 200) {
        setPasswordChanged(true);
        localStorage.removeItem("resetToken");
        localStorage.removeItem("resetEmail");
        // navigate("/login")
        // If passwords are valid, make API call and show dialog
        setPasswordChanged(true);
      } else {
        throw new Error("Could not reset password");
      }
    } catch (error) {
      setPasswordChanged(false);
      console.log(error?.response?.data?.message);
      setErrors({ ...errors, root: error?.response?.data?.message });
    }
  };

  return (
    <div className="reset-password_container">
      <div className="video-player">
        <VideoPlayer />
      </div>

      <div className="reset-form_container">
        <div className="reset-form_div">
          <Typography
            variant="h1"
            gutterBottom
            style={{
              fontFamily: "Inter",
              fontSize: "29px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Change your password
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: "16px",
              marginBottom: "2rem",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: 15,
              color: "#8692A6",
              textAlign: "center",
            }}
          >
            Enter a new password below
          </Typography>

          <form onSubmit={handleSubmit}>
            <FormControl sx={{ mb: 3 }} fullWidth variant="outlined">
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: "#666666", // Default color
                  "&.Mui-focused": {
                    color: "#666666", // Keep the color the same when focused
                  },
                  fontWeight: 500,
                }}
                htmlFor="outlined-adornment-password"
              >
                Password
              </InputLabel>
              <OutlinedInput
                sx={textFieldStyles}
                name="password"
                onChange={handlePasswordChange}
                value={passwords.password}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {errors.password && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ mt: "5px", fontSize: "12px", color: "#F51934" }}
                >
                  {errors.password}
                </Typography>
              )}
            </FormControl>

            <FormControl sx={{ mb: 5 }} fullWidth variant="outlined">
              <InputLabel
                htmlFor="confirm-password-input"
                sx={{
                  fontSize: "14px",
                  color: "#666666", // Default color
                  "&.Mui-focused": {
                    color: "#666666", // Keep the color the same when focused
                  },
                  fontWeight: 500,
                }}
              >
                Re-enter Password
              </InputLabel>
              <OutlinedInput
                sx={textFieldStyles}
                name="confirmPassword"
                onChange={handlePasswordChange}
                value={passwords.confirmPassword}
                id="confirm-password-input"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              {errors.confirmPassword && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ mt: "5px", fontSize: "12px" }}
                >
                  {errors.confirmPassword}
                </Typography>
              )}
              {errors.root && (
                <Typography
                  sx={{
                    color: "#F51934",
                    fontWeight: 500,
                    fontSize: "14px",
                    marginTop: "0.6rem",
                  }}
                >
                  {errors.root}
                </Typography>
              )}
            </FormControl>

            <button
              type="submit"
              disabled={!isFormValid}
              style={{
                cursor: !isFormValid ? "not-allowed" : "pointer",
                width: "100%",
                padding: "14px 0",
                borderRadius: 6,
                background: !isFormValid ? "#A0A0A0" : "",
                backgroundImage: isFormValid
                  ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                  : "",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "Inter",
                color: "#FFF",
                border: "1px solid #DDD",
                letterSpacing: "0.5px",
              }}
            >
              Change password
            </button>
          </form>
        </div>
      </div>
      <PasswordChangedModal
        isOpen={passwordChanged}
        close={() => setPasswordChanged(false)}
      />
    </div>
  );
};

export default TeamMembersNewPassword;
