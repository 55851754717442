import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../utils/hooks/useFetch";
import { reportEndpoints } from "../../../../api/endpoints/report.endpoints";
import "./rejected.css";
import PageHeading from "../../../common/PageHeading/PageHeading";
import RejectedTable from "./RejectedTable";
import { unitEndpoints } from "../../../../api/endpoints/unit.endpoints";
import { filterRejectedKPIs } from "../../../../utils/rejectedReports";
import GradientButton from "../../../common/GradientButton";
import OutlinedButton from "../../../common/OutlinedButton";
import usePostApi from "../../../../utils/hooks/usePost";

const RejectedReport = () => {
  const params = useParams();

  // Fetch the report data
  const {
    data: report,
    loading: reportLoading,
    error: reportError,
  } = useFetch(reportEndpoints.getReport(params?.id), {
    actionableInsight: false,
    last6YearsReports: false,
  });

  // Fetch the units data
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);

  // Generate the report
  const {
    data: generateResponse,
    error: generateError,
    loading: generateLoading,
    post: generateReport,
  } = usePostApi(reportEndpoints.generate(params.id));

  // Filter the rejected KPIs only when the report is available
  const rejectedData =
    !reportLoading && report ? filterRejectedKPIs(report?.report) : null;

  const navigate = useNavigate();

  // Loading and error handling logic
  if (reportLoading || unitsLoading) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  if (reportError || unitsError) {
    return <div>Error loading data. Please try again later.</div>; // Show an error message if data fetching fails
  }

  const handleReportGeneration = async () => {
    try {
      const res = await generateReport({}); // Wait for the API call to complete
      if (res) {
        // Only navigate if the response is successful
        navigate(`/score-card/${params.id}`);
        console.log(res); // Log the response if needed
      }
    } catch (err) {
      alert("Please fill all the data points before generating the report");
      console.log(err); // Log the error if the API call fails
    }
  };

  return (
    <div className="rejected-container">
      <PageHeading
        text={"Add essential data points"}
        onClick={() => navigate(-1)}
      />

      {rejectedData && (
        <div className="rejected-tables_container">
          {rejectedData?.basicSectorSpecificKPI && (
            <RejectedTable
              title={`Base: Sector Specific KPI`}
              unitsData={unitsData}
              reportData={rejectedData?.basicSectorSpecificKPI}
              kpiName={`basicSectorSpecificKPI`}
            />
          )}

          {rejectedData?.environmentKPI && (
            <RejectedTable
              title={`Environmental KPI`}
              unitsData={unitsData}
              reportData={rejectedData?.environmentKPI}
              kpiName={`environmentKPI`}
            />
          )}

          {rejectedData?.socialKPI && (
            <RejectedTable
              title={`Social KPI`}
              unitsData={unitsData}
              reportData={rejectedData?.socialKPI}
              kpiName={`socialKPI`}
            />
          )}

          {rejectedData?.governanceKPI && (
            <RejectedTable
              title={`Governance KPI`}
              unitsData={unitsData}
              reportData={rejectedData?.governanceKPI}
              kpiName={`governanceKPI`}
            />
          )}
        </div>
      )}

      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <OutlinedButton fontSize="0.875rem" type="button">
          Save Progress
        </OutlinedButton>
        <GradientButton
          fontSize="0.875rem"
          disable={generateLoading}
          onClick={() => {
            handleReportGeneration();
          }}
        >
          {generateLoading ? `Generating` : `Generate Report`}
        </GradientButton>
      </div>
    </div>
  );
};

export default RejectedReport;
