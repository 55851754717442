import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useLocation, useParams } from "react-router-dom";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
} from "../../../utils/data-points/cleanObj";
import useFetch from "../../../utils/hooks/useFetch";
import { unitEndpoints } from "../../../api/endpoints/unit.endpoints";
import Loader from "../../Loader";

const EnvironmentalKpi = () => {
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const reportData = data?.report?.environmentKPI;
  // const {
  //   data: saveResponse,
  //   loading: saving,
  //   error,
  //   put: saveProgress,
  // } = usePutApi(reportEndpoints.saveProgress(params.id));
  const {
    data: unitsData,
    loading: unitsLoading,
    error: unitsError,
  } = useFetch(unitEndpoints.getUnits);

  const location = useLocation();
  const isAiGenerated = location?.state?.generationMethod === "ai" || "";
  console.log(isAiGenerated);
  // const localStorageKey = `environmentalKpiData`;

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    // "Comments",
  ];

  // Initial state from localStorage or empty object
  // const [dataToMap, setDataToMap] = useState(() => {
  //   const savedData = localStorage.getItem(localStorageKey);
  //   return savedData ? JSON.parse(savedData) : {};
  // });
  const [dataToMap, setDataToMap] = useState({});

  // Sync with reportData only if localStorage is empty
  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other }; // Use only reportData initially if localStorage is empty
      // localStorage.setItem(localStorageKey, JSON.stringify(mergedData)); // Save to localStorage
      setDataToMap(mergedData); // Set state with merged data
    }
  }, [reportData, loading]);

  // Update localStorage on any input change
  const handleInputChange = (symbol, dataKey, value) => {
    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: parseFloat(value), // Update the value while keeping the unit intact
          },
        },
      };

      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ environmentKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    scope1Emissions: "Scope 1 emissions",
    scope2Emissions: "Scope 2 emissions",
    scope3Emissions: "Scope 3 emissions",
    waterConsumed: "Total Water consumption",
    wasteToLandfill: "Amount of waste disposed to landfill",
    noxReleased: "Amount of NOx released into the environment",
    soxReleased: "Amount of SOx released into the environment",
    h2sConcentration:
      "Threshold H2S concentration for the flare technology in use",
    noiseLevel: "LA90 Noise level",
    totalWasteWater: "Total waste water generated",
    wasteWaterTreated: "Total waste water treated before disposal",
  };

  const unitMapping = {
    "66d7562107b0aeb0cb66609a": "tCO2e",
    "66d7562207b0aeb0cb66609d": "million USD",
    "66d7562207b0aeb0cb6660a4": "m3",
    "66d7562207b0aeb0cb6660a9": "tonnes",
    "66d7562207b0aeb0cb6660ad": "MWh",
    "66d7562207b0aeb0cb6660af": "MWh eq",
    "66d7562207b0aeb0cb6660ab": "%",
    "66d7562207b0aeb0cb6660b3": "dB",
    "66d7562207b0aeb0cb6660b1": "ppm",
  };

  // Create a mapping of unit IDs to unit names from unitsData
  const dynamicUnitMapping = unitsData?.data?.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const finalOutput = Object?.keys(dataToMap)?.reduce((acc, symbol) => {
    acc[symbol] = Object?.keys(dataToMap[symbol])?.reduce(
      (innerAcc, dataKey) => {
        innerAcc[dataKey] = { value: dataToMap[symbol][dataKey].value };
        return innerAcc;
      },
      {}
    );
    return acc;
  }, {});

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings?.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${
                    index === 0 || index > 2 ? "centered" : ""
                  }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}

          {/* Table Body */}
          {!loading && !unitsLoading ? (
            <div className="new-kpi-table-body">
              {Object?.keys(dataToMap)?.map((symbol) => {
                if (symbolsToNotMap.includes(symbol)) return null;

                // Check if there are any missing values for the symbol
                const isSymbolMissingValue = Object.keys(
                  dataToMap[symbol]
                ).some(
                  (dataKey) =>
                    !dataPointsToNotMap.includes(dataKey) &&
                    (dataToMap[symbol][dataKey]?.value === undefined ||
                      dataToMap[symbol][dataKey]?.value === "" ||
                      dataToMap[symbol][dataKey]?.value === null ||
                      !dataToMap[symbol][dataKey]?.value)
                );

                // Determine the background color for the symbol cell
                const symbolColor =
                  isAiGenerated && isSymbolMissingValue ? "#ffeded" : "#f6fffa";

                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <div
                      className="new-kpi-table-cell centered"
                      style={{ backgroundColor: symbolColor }} // Apply background color to symbol cell
                    >
                      <div className="new-kpi-table-cell-content">{symbol}</div>
                    </div>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;
                        // Determine the background color for this specific data point
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "#ffeded" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }} // Apply background color to individual data points
                          >
                            {keyMapping[dataKey] || dataKey}
                          </div>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific data point value is missing for unit cell
                        const isDataPointMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;

                        // Determine the background color for this specific unit cell
                        const cellColor = isAiGenerated
                          ? isDataPointMissing
                            ? "#ffeded" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                            style={{ backgroundColor: cellColor }}
                          >
                            {dynamicUnitMapping[
                              dataToMap[symbol][dataKey]?.unit
                            ] || "N/A"}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                        if (dataPointsToNotMap.includes(dataKey)) return null;

                        // Check if the specific value is missing for value cell
                        const isValueMissing =
                          dataToMap[symbol][dataKey]?.value === undefined ||
                          dataToMap[symbol][dataKey]?.value === "" ||
                          dataToMap[symbol][dataKey]?.value === null ||
                          !dataToMap[symbol][dataKey]?.value;

                        // Determine the background color for this specific value cell
                        const cellColor = isAiGenerated
                          ? isValueMissing
                            ? "#ffeded" // Red if missing
                            : "#f6fffa" // Green if present
                          : "";

                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content input-wrapper"
                            style={{ backgroundColor: cellColor }} // Apply background color to individual input cells
                          >
                            <input
                              type="number"
                              value={
                                dataToMap[symbol][dataKey]?.value !==
                                  undefined &&
                                dataToMap[symbol][dataKey]?.value !== null
                                  ? dataToMap[symbol][dataKey].value
                                  : ""
                              }
                              className="input-field"
                              placeholder="Enter value"
                              onChange={(e) =>
                                handleInputChange(
                                  symbol,
                                  dataKey,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>

                    {/* Messages */}
                    {/* <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        <div className="messages-wrapper">
                          <div className="profile-images-container">
                            {[1, 2, 3].map((img) => (
                              <img
                                key={img}
                                src={dummyUser}
                                alt="Messaged"
                                height={28}
                                width={28}
                              />
                            ))}
                          </div>
                          <div className="notifications-div">
                            <img
                              src={messages}
                              alt="Messaged"
                              height={24}
                              width={24}
                            />
                            <span className="unread">2 Unread</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                );
              })}
            </div>
          ) : (
            // <p>LOADING...</p>
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default EnvironmentalKpi;
