import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
// import data from "../assets/data.json";

const PartitionChart = ({ data }) => {
  const svgRef = useRef();
  // const data = radialData;

  useEffect(() => {
    const width = 1080;
    const height = 980;
    const radius = width / 6.7;

    //   yel #FFCB77
    //   pink #FF8FA5
    //   green #02B880
    //   blue #059BFF

    const customColors = ["#059BFF", "#05cb8e", "#ff8fa5", "#ffbf57"];

    // Create a color scale for the outer segments
    const color = d3.scaleOrdinal(customColors);

    const hierarchy = d3.hierarchy(data).sum((d) => Math.max(0, d.value)); // Prevent negative values

    // .sort((a, b) => b.value - a.value);

    const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(
      hierarchy
    );

    root.each((d) => (d.current = d));

    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d) => d.y0 * radius * 1) // Reduce the inner radius
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", [-width / 2, -height / 2, width, width - 80])
      .style("font", "18px Inter");

    const path = svg
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1)) // This includes all layers
      .join("path")
      .attr("fill", (d) => {
        if (d.depth === 1) {
          return color(d.data.name);
        } else {
          while (d.depth > 1) d = d.parent;
          return color(d.data.name);
        }
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 1 : 0.4) : 0
      )
      .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
      .attr("d", (d) => arc(d.current))
      // Tooltip events only for leaf nodes
      .style("cursor", "pointer")
      .on("click", clicked)
      .on("mouseover", (event, d) => {
        if (!d.children) {
          // Only show tooltip for leaf nodes
          const tooltip = d3.select("#tooltip");
          tooltip.transition().duration(200).style("opacity", 1);
          tooltip
            .html(`Name: ${d.data.name}<br>Value: ${d.data.value}`)
            .style("left", `${event.pageX + 5}px`)
            .style("top", `${event.pageY - 28}px`);
        }
      })
      .on("mousemove", (event, d) => {
        if (!d.children) {
          // Only update position for leaf nodes
          d3.select("#tooltip")
            .style("left", `${event.pageX + 5}px`)
            .style("top", `${event.pageY - 28}px`);
        }
      })
      .on("mouseout", (event, d) => {
        if (!d.children) {
          // Only hide tooltip for leaf nodes
          d3.select("#tooltip").transition().duration(200).style("opacity", 0);
        }
      });

    path
      .filter((d) => d.children)
      .style("cursor", "pointer")
      .on("click", clicked);

    const label = svg
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .text((d) => d.data.name);

    const parent = svg
      .append("circle")
      .datum(root)
      .attr("r", radius / 2) // Match the new inner radius
      // .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);

    function clicked(event, p) {
      parent.datum(p.parent || root);

      root.each((d) => {
        d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        };
      });

      const t = svg.transition().duration(750);

      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => {
            d.current = i(t);
          };
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        // .attr("fill", "none")
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.6) : 0
        )
        .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))
        .attrTween("d", (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 0})`;
    }
  }, []);
  const tooltipStyle = {
    position: "absolute",
    pointerEvents: "none",
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "5px",
    opacity: 0,
    transition: "opacity 0.3s",
    fontSize: "10px",
    color: "grey",
  };
  return (
    <>
      <svg
        ref={svgRef}
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "600px",
          maxWidth: "600px",
        }}
      ></svg>
      <div id="tooltip" style={tooltipStyle}></div>
    </>
  );
};

export default PartitionChart;
