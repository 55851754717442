import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import redI from "../../../../assets/redI.svg";
import AssignButton from "./AssignButton";
import { allKeyMappings as keyMapping } from "../../../../utils/rejectedReports";
import {
  dataPointsToNotMap,
  dataToNotSend,
  debounce,
  filterEmptyObjects,
  removeKeys,
  symbolsToNotMap,
} from "../../../../utils/data-points/cleanObj";
import usePutApi from "../../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../../api/endpoints/report.endpoints";
import { useParams } from "react-router-dom";

const RejectedTable = ({ title, reportData, unitsData, kpiName }) => {
  const params = useParams();
  const [dataToMap, setDataToMap] = useState({});

  useEffect(() => {
    const { id, ...other } = reportData;
    const mergedData = { ...other };
    setDataToMap(mergedData);
  }, [reportData]);

  const { put: saveProgress, error: saveError } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );

  const dynamicUnitMapping = unitsData?.data?.reduce((acc, unit) => {
    acc[unit.id] = unit.name;
    return acc;
  }, {});

  const handleInputChange = (symbol, dataKey, value) => {
    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value:
              value === "true"
                ? true
                : value === "false"
                ? false
                : parseFloat(value), // Handle boolean values
          },
        },
      };
      // localStorage.setItem(localStorageKey, JSON.stringify(updatedData)); // Persist changes in localStorage
      // return updatedData;
      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, dataToNotSend);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ [kpiName]: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  return (
    <div className="rejected-kpi-table-container">
      <h2 className="rejected-table_title">{title}</h2>

      <div className="rejected-kpi-table">
        {/* <Grid
          container
          sx={{
            fontSize: "12px",
          }}
        >
          <Grid item xs={1}>
            B2
          </Grid>
          <Grid item xs={4}>
            Total Water consumption
          </Grid>
          <Grid item xs={2}>
            m3
          </Grid>
          <Grid item xs={2}>
            <input type="text" />
          </Grid>
          <Grid item xs={2}>
            Assign
          </Grid>
        </Grid> */}

        <div className="rejected-kpi-table-body">
          {Object?.keys(dataToMap)?.map((symbol) => {
            if (symbolsToNotMap.includes(symbol)) return null;

            return (
              <div
                key={`symbol-${symbol}`}
                className="rejected-kpi-table-row rejected-kpi-table-body-row"
              >
                <div className="rejected-kpi-table-cell">
                  <div className="rejected-kpi-table-cell-content">
                    {symbol}
                  </div>
                </div>
                <div className="rejected-kpi-table-cell">
                  {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                    if (dataPointsToNotMap.includes(dataKey)) return null;

                    return (
                      <div
                        key={`rejected-data-points${idx}`}
                        className="rejected-kpi-table-cell-content data"
                        // Apply background color to individual data points
                      >
                        {keyMapping[dataKey] || dataKey}
                      </div>
                    );
                  })}
                </div>

                <div className="rejected-kpi-table-cell">
                  {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                    if (dataPointsToNotMap.includes(dataKey)) return null;

                    const unit =
                      dynamicUnitMapping[dataToMap[symbol][dataKey]?.unit];
                    const showUnit = unit === "0/1" ? "Yes/No" : unit;

                    return (
                      <div
                        key={idx}
                        className="rejected-kpi-table-cell-content data"
                      >
                        {showUnit || "N/A"}
                      </div>
                    );
                  })}
                </div>

                {/* INPUT VALUES */}
                <div className="rejected-kpi-table-cell">
                  {Object?.keys(dataToMap[symbol])?.map((dataKey, idx) => {
                    if (dataPointsToNotMap.includes(dataKey)) return null;

                    const unit =
                      dynamicUnitMapping[dataToMap[symbol][dataKey].unit];
                    const value = dataToMap[symbol][dataKey].value;

                    if (unit === "0/1") {
                      return (
                        <div
                          className="new-kpi-table-cell-content input-wrapper"
                          key={idx}
                        >
                          <button
                            className={`yes-no-btn yes-btn ${
                              value === true ? "yes-btn-selected" : ""
                            }`}
                            onClick={() =>
                              handleInputChange(symbol, dataKey, "true")
                            }
                          >
                            Yes
                          </button>
                          <button
                            className={`yes-no-btn no-btn ${
                              value === false ? "no-btn-selected" : ""
                            }`}
                            onClick={() =>
                              handleInputChange(symbol, dataKey, "false")
                            }
                          >
                            No
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={idx}
                          className="new-kpi-table-cell-content input-wrapper"
                          // Apply background color to individual input cells
                        >
                          <input
                            type="number"
                            value={dataToMap[symbol][dataKey]?.value || ""}
                            className="rejected-input-field"
                            placeholder="Enter value"
                            onChange={(e) =>
                              handleInputChange(symbol, dataKey, e.target.value)
                            }
                          />

                          <img
                            src={redI}
                            height={16}
                            width={16}
                            alt="Alert"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="rejected-kpi-table-cell">
                  <div className="rejected-kpi-table-cell-content">
                    <AssignButton />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RejectedTable;
