import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  // const isAuthenticated = true;

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.removeItem("resetToken");
      localStorage.removeItem("resetEmail");
    }
  }, []);

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/signup" replace state={{ from: location }} />
  );
}
