import React, { useState, useEffect } from "react";
// import "./reset-password.css";
import VideoPlayer from "../common/VideoPlayer";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import clock from "../../assets/clock.svg";
import {
  sendOTPtoEmail,
  sendPasswordValidationOTPtoEmail,
  verifyResetPasswordOTP,
} from "../../api/auth";

const TeamResetPasswordOTP = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "", otp: "" });
  const [otp, setOtp] = useState("");
  const [otperror, setOtpError] = useState("");
  const [timer, setTimer] = useState(30); // Set initial timer to 30 seconds
  const [isResendDisabled, setIsResendDisabled] = useState(true); // Disable resend initially
  const navigate = useNavigate();
  const resetEmail = localStorage.getItem("resetEmail");

  const isOtpFilled = otp.length === 6;

  useEffect(() => {
    // Countdown logic
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendDisabled(false); // Enable resend after 30s
    }
  }, [timer]);

  const handleResendClick = async () => {
    // Resend OTP logic here
    // API FOR RESEND OTP
    try {
      await sendPasswordValidationOTPtoEmail({ user_email: resetEmail });
      setTimer(30); // Reset timer to 30 seconds
      setIsResendDisabled(true); // Disable the resend button again
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // OTP verification logic
    // setOtpError("Wrong OTP entered");
    //if success navigate to new password page

    try {
      const res = await verifyResetPasswordOTP({
        user_email: resetEmail,
        user_otp: otp,
      });
      if (res?.status === 200) {
        console.log(res.data);
        //  set the response token in local storage
        localStorage.setItem("resetToken", res.data.token);
        navigate("/team-reset-password/new-password");
      } else {
        setOtpError("Wrong OTP entered");
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!resetEmail) {
      alert("Please enter your email first");
      navigate("/team-reset-password");
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      handleSubmit();
    }
    setOtpError(false);
  }, [otp]);

  return (
    <div className="reset-password_container">
      <div className="video-player">
        <VideoPlayer />
      </div>

      <div className="reset-form_container">
        <div className="reset-form_div">
          <Typography
            variant="h1"
            sx={{
              fontFamily: "Inter",
              fontSize: "24px",
              fontWeight: 500,
              textAlign: "left",
              mb: 2,
            }}
          >
            Enter the OTP sent to your Email Address
          </Typography>

          <div className="edit-email_div_container">
            <div className="edit-email_div">
              <span className="edit-email_div_email">{resetEmail}</span>
              <button
                onClick={() => {
                  localStorage.removeItem("resetEmail");
                  navigate("/reset-password");
                }}
              >
                Edit email address
              </button>
            </div>

            <div className="resend-otp-div">
              <img src={clock} height={24} width={24} alt="Timer" />
              <span className="green_text_gradient">{timer}s</span>

              <button
                // className="green_text_gradient resend"
                onClick={handleResendClick}
                disabled={isResendDisabled} // Disable resend button while timer is running
                style={{
                  cursor: isResendDisabled ? "not-allowed" : "pointer",
                  color: isResendDisabled ? "#A0A0A0" : "#28814D", // Greyed out if disabled
                  border: "none",
                  // background:
                  //     "linear-gradient(102deg, #369d9c 0%, #28814d 100%)",
                  //   backgroundClip: "text",
                  //   webkitBackgroundClip: "text",
                  //   webkitTextFillColor: "transparent",
                  background: "none",
                }}
              >
                Resend OTP
              </button>
            </div>
          </div>

          <form>
            <div
              style={{
                marginBottom: "40px",
              }}
            >
              <MuiOtpInput
                sx={{
                  fontWeight: "600",
                  "& .MuiOutlinedInput-root fieldset": {
                    height: "50px",
                    borderColor: otperror ? "red !important" : "#F3F3F3",
                  },
                  "& .MuiInputBase-input": {
                    padding: "12px",
                    fontWeight: "600",
                  },
                }}
                value={otp}
                onChange={(value) => setOtp(value)}
                length={6}
                autoFocus
                error={!!otperror}
                errorText={otperror}
              />
              {otperror && (
                <Typography color="error" variant="body2">
                  {otperror}
                </Typography>
              )}
            </div>

            {/* <button
              type="submit"
              disabled={!isOtpFilled}
              style={{
                width: "100%",
                padding: "14px 0",
                borderRadius: 6,
                background: !isOtpFilled ? "#A0A0A0" : "",
                backgroundImage: isOtpFilled
                  ? "linear-gradient(102deg, #369D9C 0%, #28814D 100%)"
                  : "",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "Inter",
                color: !isOtpFilled ? "#FFF" : "#FFF",
                border: "1px solid #DDD",
                letterSpacing: "0.5px",
                cursor: "pointer",
              }}
            >
              Verify OTP
            </button> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default TeamResetPasswordOTP;
