import React from "react";
import {
  XYChart,
  AnimatedAreaSeries,
  AnimatedLineSeries,
  AnimatedGlyphSeries,
  Tooltip,
} from "@visx/xychart";
import { LinearGradient } from "@visx/gradient";
import { curveNatural } from "@visx/curve";
import { Axis } from "@visx/xychart";
import { extent } from "d3-array";

export default function CurvedLineChart({
  width,
  height,
  color,
  leftLabel = "In million m3",
  data = [],
}) {
  // Calculate the y-domain based on the data values and add padding
  const yDomain = extent(data.map((d) => d.value));
  const yPadding = (yDomain[1] - yDomain[0]) * 0.1; // 10% padding
  const yScaleDomain = [Math.min(0, yDomain[0]), yDomain[1] + yPadding]; // Ensure the y-axis starts from 0 or the minimum value

  return (
    <XYChart
      xScale={{ type: "band", padding: 0.1 }} // Reduced padding for better fit
      yScale={{ type: "linear", domain: yScaleDomain, nice: true }} // Linear scale for y-axis with calculated domain
      height={height}
      width={width}
      margin={{ top: 40, right: 0, bottom: 30, left: 30 }} // Reduced left margin
    >
      {/* Define the gradient */}
      <LinearGradient id="curved-green-gradient" from="#92dec7" to="#fff" />

      {/* AnimatedAreaSeries for the filled area */}
      <AnimatedAreaSeries
        dataKey="Area"
        data={data}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        fillOpacity={0.4} // Controls the opacity of the area below the line
        fill="url(#curved-green-gradient)" // Apply the gradient
        curve={curveNatural} // Apply the natural curve
      />

      {/* AnimatedLineSeries for the line with animation */}
      <AnimatedLineSeries
        dataKey="Line"
        data={data}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        stroke={color} // Updated line color
        strokeWidth={1} // Line thickness
        curve={curveNatural} // Apply the natural curve
        strokeOpacity={1} // Ensure full opacity
      />

      {/* AnimatedGlyphSeries for the data points */}
      <AnimatedGlyphSeries
        dataKey="Points"
        data={data}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        size={75} // Size of the glyph (adjust as needed)
        renderGlyph={(glyphProps) => {
          const { x, y } = glyphProps;
          return (
            <circle
              cx={x}
              cy={y}
              r={4} // Radius of the dot
              fill={color} // Dot color matching the line
            />
          );
        }}
      />

      <Axis
        orientation="bottom"
        hideAxisLine
        hideTicks
        tickLabelProps={{
          fontSize: 9,
        }}
      />
      <Axis
        orientation="left"
        hideAxisLine
        hideTicks
        numTicks={4} // Reduce the number of labels on the left axis
        label={leftLabel}
        labelOffset={0}
        labelProps={{
          fontSize: 9,
          fontWeight: 500,
          dx: "-1em",
        }}
        tickLabelProps={{
          dx: "2em",
          fontSize: 9,
        }}
      />
      <Tooltip
        offsetLeft={-120}
        offsetTop={-40}
        renderTooltip={({ tooltipData }) => (
          <div
            style={{
              padding: "8px",
              fontWeight: 400,
              fontSize: 11,
              display: "flex",
              gap: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color,
                height: 12,
                width: 12,
              }}
            ></div>
            <div>SI Score {tooltipData?.nearestDatum?.datum.value}</div>
          </div>
        )}
      />
    </XYChart>
  );
}
