function removeKeys(obj, keysToRemove) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return the value if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeys(item, keysToRemove));
  }

  const newObj = {};

  Object?.keys(obj)?.forEach((key) => {
    if (!keysToRemove?.includes(key)) {
      newObj[key] = removeKeys(obj[key], keysToRemove);
    }
  });

  return newObj;
}

function filterEmptyObjects(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj
      .map((item) => filterEmptyObjects(item))
      .filter(
        (item) =>
          item !== undefined && item !== null && item !== "" && !isNaN(item)
      ); // Exclude NaN
  }

  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null) {
      const filteredValue = filterEmptyObjects(value);
      // Only include the key if the object or array has some valid values
      if (Object.keys(filteredValue).length > 0) {
        newObj[key] = filteredValue;
      }
    } else if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      !isNaN(value) // Exclude NaN values
    ) {
      newObj[key] = value;
    }
  });

  return newObj;
}

// function filterEmptyObjects(obj) {
//   if (obj === null || typeof obj !== "object") {
//     return obj; // Return if it's not an object
//   }

//   if (Array.isArray(obj)) {
//     return obj.map((item) => {
//       const filteredItem = filterEmptyObjects(item);
//       return filteredItem !== undefined && filteredItem !== null
//         ? filteredItem
//         : null; // Replace invalid entries with null
//     });
//   }

//   const newObj = {};

//   Object.keys(obj).forEach((key) => {
//     const value = obj[key];

//     if (typeof value === "object" && value !== null) {
//       const filteredValue = filterEmptyObjects(value);
//       // If the object or array is empty, set it to null
//       newObj[key] =
//         Object.keys(filteredValue).length > 0 ? filteredValue : null;
//     } else if (
//       value !== undefined &&
//       value !== null &&
//       value !== "" &&
//       !isNaN(value) // Exclude NaN values
//     ) {
//       newObj[key] = value;
//     } else {
//       newObj[key] = null; // Set empty or invalid values to null
//     }
//   });

//   return newObj;
// }

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const dataPointsToNotMap = [
  "score",
  "grade",
  "normalizedValue",
  "kpiValue",
  "approval",
];

const symbolsToNotMap = [
  "score",
  "grade",
  "_id",
  "createdAt",
  "updatedAt",
  "__v",
  "completedStatus",
];

const dataToNotSend = [
  "id",
  "score",
  "unit",
  "grade",
  "normalizedValue",
  "kpiValue",
  "__v",
  "updatedAt",
  "createdAt",
  "_id",
  "completedStatus",
  "approval",
];

export {
  removeKeys,
  filterEmptyObjects,
  debounce,
  dataPointsToNotMap,
  symbolsToNotMap,
  dataToNotSend,
};
