import React from "react";
import "./sustainabilityIndex.css";
import GovernanceStats from "./GovernanceStats";
import BaseSectorStats from "./BaseSectorStats";
import EnvironmentalStats from "./EnvironmentalStats";
import SocialStats from "./SocialStats";
import logo from "../../assets/images/logo.png";
import expert from "../../assets/expert.svg";
import close from "../../assets/close.svg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { reportEndpoints } from "../../api/endpoints/report.endpoints";
import useFetch from "../../utils/hooks/useFetch";
import GradientButton from "../common/GradientButton";
import defaultOrg from "../../assets/defaultOrg.svg";
import OutlinedButton from "../common/OutlinedButton";
import constant from "../../constant";
import usePostApi from "../../utils/hooks/usePost";
import usePutApi from "../../utils/hooks/usePutApi";
import BaseSectorZeroState from "./zeroState/BaseSectorZeroState";
import EnvironmentalZeroState from "./zeroState/EnvironmentalZeroState";
import SocialZeroState from "./zeroState/SocialZeroState";
import GovernanceZeroState from "./zeroState/GovernanceZeroState";
import certified from "../../assets/images/certifiedLogo.svg";
import Loader from "../Loader";

const SustainabilityIndex = () => {
  const params = useParams();
  const {
    data: reportData,
    loading,
    error,
    refetch: refetchReport,
  } = useFetch(reportEndpoints.getReport(params?.id));

  const dummyStatus = "none";

  // const {
  //   data: last6Years,
  //   loading: last6YearsLoading,
  //   error: last6YearsError,
  // } = useFetch(reportEndpoints.getLast6YearsReports);
  // console.log(last6Years);

  const {
    data: statusResponse,
    error: updateStatusErr,
    loading: updatingStatus,
    put: updateStatus,
  } = usePutApi(reportEndpoints.updateReportStatus(params?.id));

  console.log(reportData?.report?.last6YearsReports?.length);

  const navigate = useNavigate();

  const disclaimer = [
    "The scorecard provides estimates based on methodology and data inputs at the time; accuracy is dependent on input quality. Standards and best practices continually evolve.",
    "Scorecard comparisons and benchmarks should be interpreted within broader organizational contexts along with other due diligence. Unique circumstances apply.The tool provides generalized comparisons to industry average",
    "While aiming for completeness, assessments have inherent constraints. Users assume responsibility for sound interpretation and application of results.",
    "No warranty is given on the fitness of the sustainability scorecard for any particular purpose. The scorecard should not be relied upon to make acquisition, divestment, investment, hiring, or other decisions without supplementary diligence",
    "The proprietary SI index methodology by GE3S takes into account only broad industrial sectors, specific sectors or clients who might have additional parameters that need to be monitored should reach out to ge3s via email/purchasing the “essentials” plan",
  ];

  const [showExpert, setShowExpert] = useState(true);

  const saveReportAsReady = async () => {
    await updateStatus({ status: "ready" })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  const sendForVerification = async () => {
    await updateStatus({ status: "sendForVerification" })
      .then((res) => {
        // refetchReport();
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  const handleNavigateToDataPoints = () => {
    navigate(`/add-data-points/${params?.id}`);
  };

  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <div className="sustainability-top-heading">
        <h1>Sustainability Index Score Card</h1>
      </div>

      {!error ? (
        <>
          {!loading ? (
            <div className="charts-container">
              <div className="sustainability-heading_container">
                <div className="sustainability-heading">
                  <img
                    // reportData?.report?.organizationDetails?.organization?.logo
                    src={
                      reportData?.report?.organizationDetails?.organization
                        ?.logo
                        ? `${constant.IMG_URL}/${reportData?.report?.organizationDetails?.organization?.logo}`
                        : defaultOrg
                    }
                    height={55}
                    width={55}
                    alt="Company Logo"
                    style={{
                      border: "1px solid #ace6e6",
                      borderRadius: "100%",
                    }}
                  />
                  <div className="sustainability-heading_title">
                    <h1>
                      {
                        reportData?.report?.organizationDetails?.organization
                          ?.name
                      }
                    </h1>{" "}
                    <span>|</span>{" "}
                    <h2>
                      Sustainability Index For {reportData?.report?.segment},{" "}
                      {reportData?.report?.year}
                    </h2>
                  </div>
                </div>
                {/* <span style={{ marginLeft: "100px" }}>
                  License No: 23879076
                </span> */}
              </div>

              {reportData?.report?.last6YearsReports?.length > 0 ? (
                <>
                  <BaseSectorStats
                    report={reportData?.report}
                    // last6YearsData={[]}
                  />

                  <EnvironmentalStats report={reportData?.report} />

                  <SocialStats report={reportData?.report} />

                  <GovernanceStats report={reportData?.report} />
                </>
              ) : (
                <>
                  <BaseSectorZeroState report={reportData?.report} />

                  <EnvironmentalZeroState report={reportData?.report} />

                  <SocialZeroState report={reportData?.report} />

                  <GovernanceZeroState report={reportData?.report} />
                </>
              )}

              <div className="disclaimer-div">
                <h3>Disclaimers</h3>
                <ul>
                  {disclaimer.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <Loader />
          )}

          {/* {showExpert ? (
            <div className="expert-div">
              <div className="expert-left_div">
                <img src={expert} alt="expert" height={35} width={35} />
                <div className="expert-left_div_text">
                  <h2>Expert Consultation to Boost Your Scores!</h2>
                  <h3>
                    Enhance your performance through personalized insights from
                    seasoned professionals
                  </h3>
                </div>
              </div>
              <div className="expert-right_div">
                <OutlinedButton fontSize={"0.875rem"}>
                  Book a call
                </OutlinedButton>
                <button
                  onClick={() => setShowExpert(false)}
                  className="close-btn"
                >
                  <img src={close} alt="Close" height={15} width={15} />
                </button>
              </div>
            </div>
          ) : null} */}

          {!loading && dummyStatus === "vetted" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={certified} alt="Certified" height={120} width={180} />
            </div>
          ) : (
            <div className="sustainability-bottom_div-container">
              <div className="sustainability-bottom_div1">
                <button
                  disabled={
                    reportData?.report?.status === "sendForVerification"
                    // ||
                    // reportData?.report?.status === "draft"
                  }
                  className="btn-no_borders"
                  onClick={saveReportAsReady}
                >
                  Save as Ready
                </button>
                <button
                  onClick={handleNavigateToDataPoints}
                  className="btn-no_borders"
                >
                  Edit Data Points
                </button>
                <GradientButton
                  // disabled={
                  //   reportData?.report?.status === "sendForVerification"
                  // }
                  onClick={sendForVerification}
                  height={"45px"}
                  fontSize={"0.875rem"}
                >
                  Send for Vetting
                </GradientButton>
              </div>
            </div>
          )}
        </>
      ) : error?.response?.status === 401 ? (
        <p>You are not authorized to access this page! Please login first.</p>
      ) : (
        <p>Error loading data</p>
      )}
    </div>
  );
};

export default SustainabilityIndex;
