import "./SideBar.css";
import "./teamMembersSidebar.css";

import logo from "../../../assets/images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import premium from "../../../assets/premium.svg";
import ge3sLogo from "../../../assets/ge3s-logo.png";
import ReportsSvg from "./ReportsSvg";

function TeamMembersSideBar() {
  const location = useLocation();
  const [showNotification, setShowNotification] = useState(false);
  const [daysLeft, setDaysleft] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(null);

  const links = [
    { id: 0, name: "SI Report 2020", notifications: 10 },
    { id: 1, name: "SI Report 2020 1", notifications: 10 },
  ];

  const pathname = location.pathname;

  return (
    <div className="sidemenu">
      <div className="sidemanu_content">
        <div className="sidemenu_brand">
          <img src={logo} alt="logo" height={80} width={80} />

          <div className="subscription_side">
            <h6>Star Bucks</h6>
          </div>
        </div>

        <div className={`team_sidebar-links_container`}>
          {links?.map((item) => {
            const isActive = pathname.includes(item?.id);

            return (
              <NavLink
                to={`/team/data-points/${item?.id}`}
                key={item.id}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  className={`team_sidebar-link ${
                    isActive ? "team_sidebar-link_active" : ""
                  } `}
                >
                  <ReportsSvg color={isActive ? "#fff" : "#96CDCC"} />
                  <span className="team_sidebar-link_name">{item.name}</span>
                  <div className="team-members_notification_num">1</div>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TeamMembersSideBar;
