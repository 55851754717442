import React from "react";
import {
  XYChart,
  AnimatedAreaSeries,
  AnimatedLineSeries,
  AnimatedGlyphSeries,
  Tooltip,
  Grid,
} from "@visx/xychart";
import { LinearGradient } from "@visx/gradient";
import { curveNatural, curveMonotoneX } from "@visx/curve";
import { Axis } from "@visx/xychart";
import { extent } from "d3-array"; // Import extent to calculate the min and max values of the data

// Function to generate a unique ID for gradients
const generateUniqueId = () =>
  `gradient-${Math.random().toString(36).substr(2, 9)}`;

export default function LineAreaGraph({
  width,
  height,
  lineColor,
  gradientFrom = "#8debff", // Default Gradient start color
  gradientTo = "#ffffff", // Default Gradient end color
  data,
  leftLabel = "",
  tooltipLabel,
  unit = "",
}) {
  // Generate a unique gradient ID for this instance
  const gradientId = React.useMemo(() => generateUniqueId(), []);

  // Default data in case no data is provided
  const newData = data || [
    { quarter: "Q1 2023", value: 10 },
    { quarter: "Q2 2023", value: 30 },
    { quarter: "Q3 2023", value: 45 },
    { quarter: "Q4 2023", value: 35 },
    { quarter: "Q1 2024", value: 80 },
    { quarter: "Q2 2024", value: 100 },
  ];

  // Calculate the y-domain based on the data values
  const yDomain = extent(newData.map((d) => d.value));
  const yScaleDomain = [Math.min(0, yDomain[0]), yDomain[1]]; // Ensures the y-axis starts from 0 or the minimum value

  return (
    <XYChart
      xScale={{ type: "band", padding: 0.1 }} // Band scale for x-axis
      yScale={{ type: "linear", domain: yScaleDomain, nice: true }} // Linear scale for y-axis with calculated domain
      height={height}
      width={width}
      margin={{
        top: 40,
        right: 0,
        bottom: 30,
        left: leftLabel !== "" ? 50 : 30,
      }} // Adjust margins
    >
      {/* Define the gradient using a unique ID */}
      <LinearGradient
        id={gradientId} // Use the unique gradient ID
        from={gradientFrom} // Use the gradientFrom prop
        to={gradientTo} // Use the gradientTo prop
      />

      <Grid
        columns={true}
        rows={true}
        numTicks={4}
        // stroke="#f3f3f3"
        lineStyle={{
          accentColor: "#f3f3f3",
          width: 1,
          dotted: true,
          strokeDasharray: "4 4",
          columnRuleStyle: "solid",
          rowRuleStyle: "dashed",
        }}
      />

      {/* AnimatedAreaSeries for the filled area */}
      <AnimatedAreaSeries
        dataKey="Area"
        data={newData}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        fillOpacity={0.4} // Controls the opacity of the area below the line
        fill={`url(#${gradientId})`} // Apply the gradient using the unique ID
        curve={curveMonotoneX} // Apply the natural curve
      />

      {/* AnimatedLineSeries for the line with animation */}
      <AnimatedLineSeries
        dataKey="Line"
        data={newData}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        stroke={lineColor} // Updated line color
        curve={curveMonotoneX} // Apply the natural curve
      />

      {/* AnimatedGlyphSeries for the data points */}
      <AnimatedGlyphSeries
        dataKey="Points"
        data={newData}
        xAccessor={(d) => d.quarter}
        yAccessor={(d) => d.value}
        size={75} // Size of the glyph (adjust as needed)
        renderGlyph={(glyphProps) => {
          const { x, y } = glyphProps;
          return (
            <circle
              cx={x}
              cy={y}
              r={4} // Radius of the dot
              fill={lineColor} // Dot color matching the line
            />
          );
        }}
      />

      {/* X-Axis */}
      <Axis
        orientation="bottom"
        hideAxisLine
        hideTicks
        tickLabelProps={{
          fontSize: 9,
        }}
      />

      {/* Y-Axis */}
      <Axis
        orientation="left"
        hideAxisLine
        label={leftLabel}
        hideTicks
        numTicks={4} // Reduce the number of labels on the left axis
        tickLabelProps={{
          fontSize: 9,
        }}
        labelProps={{
          fontSize: 9,
          fontWeight: 500,
          dx: "-1.5em",
        }}
      />

      {/* Tooltip for displaying data values */}
      <Tooltip
        offsetLeft={-120}
        offsetTop={0}
        renderTooltip={({ tooltipData }) => (
          <div
            style={{
              padding: "8px",
              fontWeight: 400,
              fontSize: 11,
              display: "flex",
              gap: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: lineColor,
                height: 12,
                width: 12,
              }}
            ></div>
            <div>
              {tooltipLabel} {tooltipData?.nearestDatum?.datum.value} {unit}
            </div>
          </div>
        )}
      />
    </XYChart>
  );
}
